<template>
<div>
        <TitleAdmin msg="Permissions" routerAdmin="Admin"/>
    <p>Create</p>
    <div class="row">
        <div class="col">
            <form>
                <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-2 col-form-label">Name</label>
                    <div class="col-sm-10">
                        <input type="name" class="form-control" v-model="name" />
                    </div>
                </div>
                <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-2 col-form-label">Description</label>
                    <div class="col-sm-10">
                        <input type="description" class="form-control" v-model="description" />
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary" @click="newPermission">Guardar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <p>Permission</p>
    <div class="row">
        <div class="col">
            <div v-for="permission in permissions" :key="permission.id">
                <p>{{ permission.id }}</p>
                <p>{{ permission.name }}</p>
                <p>{{ permission.guard_name }}</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import TitleAdmin from '@/views/constants/titleAdmin.vue'
import axios from 'axios'

export default {
    components:{
        TitleAdmin,
    },
    name: 'cPermissions',
    data() {
        return {
            permissions: '',
            name: '',
            description: ''
        }
    },
    created() {
        this.getpermissions();
    },
    methods: {
        getpermissions() {
            const url = this.appDomainApi1 + "super-admin/permissions/index";
            axios.get(url, )
                .then(res => {
                    this.permissions = res.data
                })
                .catch(err => {
                    console.error(err);
                })
        },
        newPermission(e) {
            e.preventDefault();
            const url = this.appDomainApi1 + "super-admin/permissions/store";
            const params = {
                name: this.name,
                description: this.description
            };
            const letf = this;
            axios.post(url, params)
                .then(res => {
                    console.log(res)
                    letf.getpermissions();
                })
                .catch(err => {
                    console.error(err);
                })
        },
    },
}
</script>
